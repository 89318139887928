<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100"
    style="height: 50vh"
  >
    <span class="block p-2 sr-only">{{ $t("general.loading") }}</span>
    <b-spinner class="block align-center" />
  </div>
</template>

<script>
export default { name: "Spinner" };
</script>

<style>
</style>