<template>
  <div id="pricing-plan" v-if="!preLoading">
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">{{ $t("subscription.plans-title") }}</h1>
    </div>

    <b-alert show variant="danger" class="text-center" v-if="cancelled">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="XIcon" />
        <span class="ml-25"> {{ $t("subscription.checkout-cancelled") }} </span>
      </div>
    </b-alert>

    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col md="4" v-for="(plan, index) in plans" :key="plan.stripe_id">
            <b-card
              align="center position-relative"
              :class="plan.popular ? 'popular' : ''"
            >
              <div
                v-show="plan.stripe_id == 'basic' && inTrial"
                class="trial-badge text-right"
              >
                <b-badge
                  variant="light-danger"
                  pill
                  style="font-size: 1rem; padding: 0.5rem"
                >
                  {{ $t("subscription.trial") }}
                </b-badge>
              </div>
              <div v-show="plan.popular" class="pricing-badge text-right">
                <b-badge
                  variant="light-primary"
                  pill
                  style="font-size: 1rem; padding: 0.5rem"
                >
                  {{ $t("subscription.popular") }}
                </b-badge>
              </div>
              <div class="plan-image">
                <!-- img -->
                <b-img :src="images[index]" alt="basic svg img" />
              </div>
              <!--/ img -->
              <h3>{{ plan.title }}</h3>
              <b-card-text>
                {{ plan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">
                    $
                  </sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{ plan.price.month.amount }}</span
                  >
                  <sub
                    class="
                      pricing-duration
                      text-body
                      font-medium-1 font-weight-bold
                    "
                  >
                    /month
                  </sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in plan.features"
                  :key="index"
                >
                  {{ data.title }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-if="!inTrial && activePlanId == plan.price.month.id"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                :variant="'success'"
                :disabled="loading"
              >
                <b-spinner small v-if="loading" />
                <span class="sr-only" v-if="loading">
                  {{ $t("general.loading") }}</span
                >
                <span v-else>
                  {{ $t("subscription.current-plan") }}
                </span>
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                :variant="
                  inTrial && activePlanId == plan.price.month.id
                    ? 'primary'
                    : 'primary'
                "
                @click="selectPlan(plan.price.month.id)"
                :disabled="loading"
              >
                <template v-if="loading">
                  <b-spinner small />
                  <span class="sr-only"> {{ $t("general.loading") }}</span>
                </template>
                <template v-else>
                  <span v-if="!activePlanId">
                    {{ $t("subscription.select-plan") }}
                  </span>
                  <span
                    v-else-if="inTrial && activePlanId == plan.price.month.id"
                  >
                    {{ $t("subscription.make-payment") }}
                  </span>
                  <span v-else>
                    {{ $t("subscription.swap") }}
                  </span>
                </template>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!--/ title text and switch button -->
  </div>
  <spinner v-else />
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import { plans, select } from "@/api/subscriptions.api.js";
const pot1 = require("@/assets/images/illustration/Pot1.svg");
const pot2 = require("@/assets/images/illustration/Pot2.svg");
const pot3 = require("@/assets/images/illustration/Pot3.svg");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "pricing",
  components: { Spinner },
  data() {
    return {
      preLoading: true,
      loading: false,
      images: [pot1, pot2, pot3],
      activePlanId: null,
      plans: {},
    };
  },
  computed: {
    cancelled() {
      return this.$route.query.cancelled;
    },
    inTrial() {
      return this.$auth?.user()?.organization.trial_ends_at;
    },
  },
  methods: {
    getPlanInfo(plan, field) {
      return this.plans[plan][field] ?? null;
    },
    async fetchData() {
      this.preLoading = true;
      const {
        data: { data, active_plan_id },
      } = await plans();
      this.plans = data;
      this.activePlanId = active_plan_id;
      this.preLoading = false;
    },
    async selectPlan(priceId) {
      this.loading = true;
      const { data, error } = await select({ stripe_price_id: priceId });

      if (data) {
        if (data.redirect_url) {
          this.$toast({
            component: ToastificationContent,
            props: data.message,
          });

          setTimeout(() => {
            this.loading = false;
            window.location.href = data.redirect_url;
          }, 3000);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: data.message,
          });

          this.loading = false;
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "subscription.unexpected-error-during-redirect",
            icon: "XIcon",
            variant: "danger",
          },
        });

        this.loading = false;
      }
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.plan-image {
  display: grid;
  align-items: end;
  justify-content: center;
  height: 115px;
  margin-bottom: 1rem;
}
.trial-badge,
.pricing-badge {
  position: absolute;
  top: 0;
  margin: 1rem;
}

.pricing-badge {
  right: 0;
}

.trial-badge {
  left: 0;
}
</style>
