import axios from "@/libs/axios";


export async function plans(query) {
    try {
        const { data } = await axios.get("/api/v1/subscriptions/plans", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function show() {
    try {
        const { data: { data } } = await axios.get("/api/v1/subscriptions");
        return { data }
    } catch (error) {
        return { error }
    }
}


export async function swap(query) {
    try {
        const { data } = await axios.get("/api/v1/subscriptions/plans/swap", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}


export async function select(query) {
    try {
        const { data } = await axios.get("/api/v1/subscriptions/plans/select", { params: query })
        return { data }
    } catch (error) {
        return { error }
    }
}

export async function info() {
    try {
        const { data: { data, period_ends_at } } = await axios.get("/api/v1/subscriptions/info")
        return { data: { ...data, period_ends_at } }
    } catch (error) {
        return { error: error.response }
    }
}


export async function cancel(id) {
    try {
        const { data: { data } } = await axios.post(`/api/v1/subscriptions/${id}/cancel`)
        return { data: { data } }
    } catch (error) {
        return { error }
    }
}


export async function restore(id) {
    try {
        const { data: { data } } = await axios.post(`/api/v1/subscriptions/${id}/restore`)
        return { data: { data } }
    } catch (error) {
        return { error }
    }
}

export async function cancelUpgrade(id) {
    try {
        const { data: { data } } = await axios.post(`/api/v1/subscriptions/${id}/cancel-upgrade`)
        return { data: { data } }
    } catch (error) {
        return { error }
    }
}

